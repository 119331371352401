import * as React from 'react'

import { Position, Tooltip } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MapViewSetUpStore, {
  MapViewConfigType,
  SetUpSteps,
} from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/MapViewSetUp.store'
import PropertiesPanel from '~/client/src/desktop/views/ProjectSetUp/components/AppsSitemap/components/PropertiesPanel/PropertiesPanel'
import * as Icons from '~/client/src/shared/components/Icons'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import GlobeView from '~/client/src/shared/models/GlobeView'
import IGeoPosition from '~/client/src/shared/models/IGeoPosition'
import Sitemap from '~/client/src/shared/models/Sitemap'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import { NORTH_HEADING, WORLD_BOUNDS } from '~/client/src/shared/utils/Address'

import Checkbox from '../../../shared/components/Checkbox'
import MapBoxViewerStore from '../../../shared/components/MapBoxEditor/MapBoxViewer.store'
import InitialState from '../../../shared/stores/InitialState'
import { ToastTheme, showToast } from '../../../shared/utils/toaster'
import GlobeAssociationControl from '../../views/ProjectSetUp/components/AppsSitemap/GlobeAssociationControl'
import SitemapAssociationControl from '../../views/ProjectSetUp/components/AppsSitemap/components/SitemapAssociationControl'
import MapBoxEditorStore from '../../views/ProjectSetUp/components/AppsSitemap/stores/MapBoxEditor.store'

import './GlobeProperties.scss'

const doneEditingInterval = 1000

const maxDegrees = 360
const maxZoomValue = 21
const minZoomValue = 0
const zoomStep = 0.01
const bearingStep = 1
const maxCoords = 90
const minCoords = -90

const latitude = 'Latitude'
const longitude = 'Longitude'
const whiteboardProperties = 'Whiteboard properties'
const planProperties = 'Plan properties'
const viewProperties = 'View properties'
const plan = 'Plan'
const parameters = 'parameters'
const zoomText = 'Zoom'
const rotationText = 'Rotation'
const showViewIn = 'Show view in'
const updateGeoreferencing = 'Update Georeferencing'
const updatePlanImage = 'Update Plan Image'
const lbsTag = 'LBS Tag'
const copyValues = 'Copy values'
const pasteValues = 'Paste values'

export interface IProps {
  viewport: IGeoPosition
  store: MapViewSetUpStore

  globe: GlobeView
  updateGlobeView: (
    geoposition?: IGeoPosition,
    bounds?,
    items?,
    sitemaps?,
  ) => void

  mapBoxViewerStore: MapBoxViewerStore
  mapBoxEditorStore: MapBoxEditorStore
  isItemSelected: boolean
  selectedSitemap?: Sitemap

  saveAlignment: () => void
  exitRubber: () => void

  isGlobeMode?: boolean
  state?: InitialState
  eventsStore?: EventsStore
  sitemapsStore?: SitemapsStore
  common?: CommonStore
  isDisabled?: boolean
}

// TODO: extract all the text to the top
@inject('state', 'eventsStore', 'sitemapsStore', 'common')
@observer
export default class GlobeProperties extends React.Component<IProps> {
  @observable private savingTimer: number
  @observable private latSavingTimer: number
  @observable private lngSavingTimer: number
  @observable private zoomSavingTimer: number
  @observable private bearingSavingTimer: number
  @observable private position: IGeoPosition = {
    latitude: 0,
    longitude: 0,
    zoom: 0,
    bearing: NORTH_HEADING,
    pitch: 0,
    bounds: {
      ne: WORLD_BOUNDS._ne,
      sw: WORLD_BOUNDS._sw,
    },
  }

  public componentDidUpdate(props: IProps) {
    if (
      props.viewport.latitude !== this.props.viewport.latitude ||
      props.viewport.longitude !== this.props.viewport.longitude
    ) {
      this.position = this.props.viewport
    }
  }

  public componentDidMount(): void {
    this.position = this.props.viewport
  }

  public render(): JSX.Element {
    const {
      isGlobeMode,
      isItemSelected,
      selectedSitemap,
      saveAlignment,
      exitRubber,
      isDisabled,
      mapBoxEditorStore,
    } = this.props

    return (
      <div
        className={classList({
          'globe-properties-panel full-height relative': true,
          'globe-panel': isGlobeMode,
          'inactive-element': isDisabled,
        })}
      >
        {isItemSelected ||
        (selectedSitemap?.id && mapBoxEditorStore.isRubberMode) ? (
          <PropertiesPanel
            store={this.props.store}
            step={SetUpSteps.alignPlan}
            saveAlignment={saveAlignment}
            exitRubber={exitRubber}
            viewport={this.props.viewport}
            isGlobeMode={isGlobeMode}
          />
        ) : (
          <div className="full-height scrollable">{this.renderContent()}</div>
        )}
      </div>
    )
  }

  private renderContent(): JSX.Element {
    const { store, selectedSitemap } = this.props

    if (selectedSitemap?.id) {
      return (
        <div className="col bb-light-cool-grey">
          <div className="col py10">
            <div className="px10 row pb12 bb-light-input-border">
              <div className="text header bold center">
                {selectedSitemap?.isReferenced
                  ? planProperties
                  : whiteboardProperties}
              </div>
            </div>
            {this.renderHeader()}
            <div className="px10">
              <StruxhubInput
                label={Localization.translator.name}
                value={store.sitemapsSetupStore.editableSitemapName}
                isRequired={true}
                isRequiredTextHidden={true}
                isMinimalisticMode={true}
                shouldLineUpLabel={true}
                hideBottomLabel={true}
                onChange={this.changeSitemapName}
                onKeyDown={this.sitemapKeyDownAction}
                onKeyUp={this.sitemapKeyUpAction}
              />
            </div>
          </div>
          {this.renderLBSTag()}
          {selectedSitemap?.isReferenced
            ? this.renderWhiteboardProperties()
            : this.renderShowView()}
        </div>
      )
    }

    return (
      <div className="col">
        <div className="col py10">
          <div className="row pb12 bb-light-input-border">
            <div className="text header bold center">{viewProperties}</div>
          </div>
          <div className="px10">
            <StruxhubInput
              label={Localization.translator.name}
              value={store.globeViewSetupStore.editableGlobeName}
              onChange={this.changeGlobeName}
              onKeyDown={this.keyDownAction}
              onKeyUp={this.keyUpAction}
              isRequiredTextHidden={true}
              isMinimalisticMode={false}
            />
          </div>
          {this.renderLBSTag()}
        </div>
        {this.renderOrientation()}
        {this.renderShowView()}
      </div>
    )
  }

  private renderWhiteboardProperties(): JSX.Element {
    const { sitemapsStore, selectedSitemap } = this.props

    return (
      <>
        {this.renderOrientation()}
        <>
          <div
            className="pa10 text large blue center pointer"
            onClick={this.onSitemapGeoEditClick.bind(
              null,
              sitemapsStore.byId.get(selectedSitemap.id),
            )}
          >
            {updateGeoreferencing}
          </div>
          <div
            className="pa10 text large blue center pointer"
            onClick={this.props.store.sitemapControlStore.toggleEditMenu}
          >
            {updatePlanImage}
          </div>
        </>
      </>
    )
  }

  private renderOrientation(): JSX.Element {
    const { selectedSitemap } = this.props
    const label = selectedSitemap
      ? `${plan} ${parameters}`
      : `${Localization.translator.view_noun} ${parameters}`

    return (
      <div className="col pa10 bb-light-cool-grey">
        <div className="row">
          <div className="text large pb12 bold">{label}</div>
          {this.renderDuplicate()}
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={latitude}
            type="number"
            isRequired={true}
            onKeyDown={this.latKeyDownAction}
            onKeyUp={this.latKeyUpAction}
            value={this.position.latitude.toString()}
            onChange={this.changeLat}
            negativeOrDecimal={true}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            min={minCoords}
            max={maxCoords}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={longitude}
            type="number"
            isRequired={true}
            onKeyDown={this.lngKeyDownAction}
            onKeyUp={this.lngKeyUpAction}
            value={this.position.longitude.toString()}
            onChange={this.changeLng}
            negativeOrDecimal={true}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
            min={minCoords}
            max={maxCoords}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={zoomText}
            type="number"
            isRequired={true}
            max={maxZoomValue}
            min={minZoomValue}
            step={zoomStep}
            onKeyDown={this.zoomKeyDownAction}
            onKeyUp={this.zoomKeyUpAction}
            value={this.position.zoom.toString()}
            onChange={this.changeZoom}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
          />
        </div>
        <div className="relative duplicate-input">
          <StruxhubInput
            label={rotationText}
            type="number"
            isRequired={true}
            max={maxDegrees}
            min={-maxDegrees}
            step={bearingStep}
            onKeyDown={this.bearingKeyDownAction}
            onKeyUp={this.bearingKeyUpAction}
            value={this.position.bearing.toString()}
            onChange={this.changeBearing}
            isRequiredTextHidden={true}
            shouldLineUpLabel={true}
            isMinimalisticMode={false}
            hideBottomLabel={true}
          />
        </div>
      </div>
    )
  }

  private renderLBSTag(): JSX.Element {
    const { store, selectedSitemap } = this.props
    if (selectedSitemap?.id) {
      return (
        <div className="pa10">
          <div className="row text uppercase small light lp15 font-w-600">
            {lbsTag}
          </div>
          <div className="row">
            <SitemapAssociationControl
              mapViewItemsSetupStore={store.mapViewItemsSetupStore}
              sitemapsSetupStore={store.sitemapsSetupStore}
              shouldHideModal={
                !store.sitemapsSetupStore.isAssignSitemapDialogShown ||
                store.isGlobeListShown
              }
              sitemap={selectedSitemap}
              onToggle={store.sitemapsSetupStore.toggleAssignSitemapDialog}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="px10">
        <div className="row text uppercase small light lp15 font-w-600">
          {lbsTag}
        </div>
        <div className="row">
          <GlobeAssociationControl
            mapViewItemsSetupStore={store.mapViewItemsSetupStore}
            globeViewSetupStore={store.globeViewSetupStore}
            shouldHideModal={
              !store.globeViewSetupStore.isAssignGlobeDialogShown ||
              store.isGlobeListShown
            }
            globe={store.globeViewSetupStore.selectedGlobeView}
          />
        </div>
      </div>
    )
  }

  private renderHeader(): JSX.Element {
    return (
      <div className="row pa10 x-end">
        <Icons.Delete
          className="no-grow pointer"
          onClick={this.deleteSitePlan}
        />
      </div>
    )
  }

  private deleteSitePlan = (): void => {
    this.props.store.sitemapControlStore.showDeleteDialog()
  }

  private renderDuplicate = (): JSX.Element => {
    return (
      <>
        <Tooltip
          className="bp3-dark no-grow"
          content={copyValues}
          position={Position.BOTTOM}
        >
          <Icons.CopyOrientation
            className="pointer no-grow mx10"
            onClick={this.copyText}
          />
        </Tooltip>
        <Tooltip
          className="bp3-dark no-grow"
          content={pasteValues}
          position={Position.BOTTOM}
        >
          <Icons.PasteOrientation
            className="pointer no-grow mx10"
            onClick={this.pasteText}
          />
        </Tooltip>
      </>
    )
  }

  private onSitemapGeoEditClick = (sitemap: Sitemap): void => {
    const { store } = this.props
    store.sitemapsSetupStore.selectSitemap(sitemap, false)
    store.setStep(SetUpSteps.alignPlan)
    store.mapBoxViewerStore.setViewportFromAddress(sitemap, true)
    store.globeViewsStore.editingGlobeId = null
    store.mapBoxEditorStore.setLeftVisibility()
  }

  private copyText = async (): Promise<void> => {
    const text = `${this.position.longitude};${this.position.latitude};${this.position.zoom};${this.position.bearing}`
    await navigator.clipboard
      .writeText(text)
      .then(() =>
        showToast(
          Localization.translator.copiedToClipboard,
          ToastTheme.SUCCESS,
          IconNames.TICK,
        ),
      )
  }

  private pasteText = async (): Promise<void> => {
    await navigator.clipboard.readText().then(result => {
      const [lng, lat, zoom, bearing] = result.split(';')
      if (
        !lng ||
        isNaN(Number(lng)) ||
        !lat ||
        isNaN(Number(lat)) ||
        !zoom ||
        isNaN(Number(zoom)) ||
        !bearing ||
        isNaN(Number(bearing))
      ) {
        showToast('Wrong input', ToastTheme.ERROR, IconNames.ERROR)
      } else {
        this.position.latitude = Number(lat)
        this.position.longitude = Number(lng)
        this.position.zoom = Number(zoom)
        this.position.bearing = Number(bearing)
        const {
          store: { mapBoxViewerStore },
        } = this.props

        mapBoxViewerStore.setViewportProp(this.position.latitude, 'latitude')
        mapBoxViewerStore.setViewportProp(this.position.longitude, 'longitude')
        mapBoxViewerStore.setViewportProp(this.position.zoom, 'zoom')
        mapBoxViewerStore.setViewportProp(this.position.bearing, 'bearing')
        showToast(
          Localization.translator.successfullyUpdated,
          ToastTheme.SUCCESS,
          IconNames.TICK,
        )
      }
    })
  }

  private setOrientation = (orientation: number): void => {
    const { setViewportProp } = this.props.store.mapBoxViewerStore
    let bearing = orientation
    if (bearing > maxDegrees) {
      bearing = maxDegrees
    } else if (bearing < -maxDegrees) {
      bearing = -maxDegrees
    }
    setViewportProp(bearing, 'bearing')
  }

  private setZoom = (newZoom: number): void => {
    const { setViewportProp } = this.props.store.mapBoxViewerStore
    let zoom = newZoom
    if (zoom > maxZoomValue) {
      zoom = maxZoomValue
    } else if (zoom < minZoomValue) {
      zoom = minZoomValue
    }
    setViewportProp(zoom, 'zoom')
  }

  private updateLatitude = (): void => {
    const {
      store: { mapBoxViewerStore },
    } = this.props

    mapBoxViewerStore.setViewportProp(this.position.latitude, 'latitude')
  }

  private updateLongitude = (): void => {
    const {
      store: { mapBoxViewerStore },
    } = this.props

    mapBoxViewerStore.setViewportProp(this.position.longitude, 'longitude')
  }

  private renderShowView(): JSX.Element {
    const { selectedSitemap, state, store } = this.props
    const {
      isDeliveriesDisabled,
      isFormsDisabled,
      isLogisticsDisabled,
      isTrackerDisabled,
    } = state
    const {
      logisticsAssignedGlobes,
      formsAssignedGlobes,
      activitiesAssignedGlobes,
      deliveriesAssignedGlobes,
      logisticsAssignedSitemaps,
      formsAssignedSitemaps,
      activitiesAssignedSitemaps,
      deliveriesAssignedSitemaps,
      globeViewSetupStore: { selectedGlobeViewId, selectedGlobeView },
      onGlobeSectionClick,
      onWhiteboardSectionClick,
      isGlobeMode,
    } = store

    return (
      <div className="col view-select-globe-props pa10">
        <div className="text large">{showViewIn}</div>
        <div className="pa10">
          {!isLogisticsDisabled && (
            <div className="row mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? logisticsAssignedGlobes[selectedGlobeViewId]
                    : logisticsAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.logistics,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.logistics,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.HomeAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.home}</div>
              </div>
            </div>
          )}
          {!isFormsDisabled && (
            <div className="row mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? formsAssignedGlobes[selectedGlobeViewId]
                    : formsAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.forms,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.forms,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.FormAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.forms}</div>
              </div>
            </div>
          )}
          {!isDeliveriesDisabled && (
            <div className="row text mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? deliveriesAssignedGlobes[selectedGlobeViewId]
                    : deliveriesAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.deliveries,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.deliveries,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.DeliveryAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.deliveries}</div>
              </div>
            </div>
          )}
          {!isTrackerDisabled && (
            <div className="row text mt10 y-center">
              <Checkbox
                className="pointer reverted-colors"
                isChecked={
                  isGlobeMode
                    ? activitiesAssignedGlobes[selectedGlobeViewId]
                    : activitiesAssignedSitemaps[selectedSitemap?.id]
                }
                onClick={
                  isGlobeMode
                    ? onGlobeSectionClick.bind(
                        null,
                        selectedGlobeView,
                        MapViewConfigType.activities,
                      )
                    : onWhiteboardSectionClick.bind(
                        null,
                        selectedSitemap,
                        MapViewConfigType.activities,
                      )
                }
              />
              <div className="bg-light-cool-grey brada4 row no-grow px5">
                <Icons.ScheduleAssignMap className="no-grow mr5" />
                <div className="text">{Localization.translator.activities}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  private changeGlobeName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.props.store.globeViewSetupStore.changeName(event)
  }

  private changeSitemapName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.props.store.sitemapsSetupStore.updateEditableSitemapName(
      event.target.value,
    )
  }

  private keyUpAction = (): void => {
    clearTimeout(this.savingTimer)

    this.savingTimer = window.setTimeout(
      this.props.store.globeViewSetupStore.saveGlobeViewName,
      doneEditingInterval,
    )
  }

  private keyDownAction = (): void => {
    clearTimeout(this.savingTimer)
  }

  private sitemapKeyUpAction = (): void => {
    clearTimeout(this.savingTimer)

    this.savingTimer = window.setTimeout(
      this.props.store.sitemapsSetupStore.updateSelectedSitemapName,
      doneEditingInterval,
      this.props.isGlobeMode,
    )
  }

  private sitemapKeyDownAction = (): void => {
    clearTimeout(this.savingTimer)
  }

  private changeLng = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.position.longitude = Number(event.currentTarget.value)
  }

  private lngKeyUpAction = (): void => {
    clearTimeout(this.lngSavingTimer)

    this.lngSavingTimer = window.setTimeout(
      this.updateLongitude,
      doneEditingInterval,
    )
  }

  private lngKeyDownAction = (): void => {
    clearTimeout(this.lngSavingTimer)
  }

  private changeLat = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.position.latitude = Number(event.currentTarget.value)
  }

  private latKeyUpAction = (): void => {
    clearTimeout(this.latSavingTimer)

    this.latSavingTimer = window.setTimeout(
      this.updateLatitude,
      doneEditingInterval,
    )
  }

  private latKeyDownAction = (): void => {
    clearTimeout(this.latSavingTimer)
  }

  private changeZoom = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.position.zoom = Number(event.currentTarget.value)
  }

  private zoomKeyUpAction = (): void => {
    clearTimeout(this.zoomSavingTimer)

    this.zoomSavingTimer = window.setTimeout(
      this.setZoom.bind(null, this.position.zoom),
      doneEditingInterval,
    )
  }

  private zoomKeyDownAction = (): void => {
    clearTimeout(this.zoomSavingTimer)
  }

  private changeBearing = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    this.position.bearing = Number(event.currentTarget.value)
  }

  private bearingKeyUpAction = (): void => {
    clearTimeout(this.bearingSavingTimer)

    this.bearingSavingTimer = window.setTimeout(
      this.setOrientation.bind(null, this.position.bearing),
      doneEditingInterval,
    )
  }

  private bearingKeyDownAction = (): void => {
    clearTimeout(this.bearingSavingTimer)
  }
}
